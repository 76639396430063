import { createStore } from 'redux';
import { combineReducers } from 'redux';
import {Issues, Exhibitions, About} from './reducers';

const reducers = combineReducers({
    issues: Issues,
    exhibitions: Exhibitions,
    about: About
})

export default createStore(reducers)
