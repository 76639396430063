import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Facebook from '../img/facebook.svg';
import Pinterest from '../img/pinterest.svg';
import Mail from '../img/mail.png';

const Menu = (props) => {
  const issuesAndExhibitions = _.orderBy(props.issues.concat(props.exhibitions), ['fields.publishDate'], ['desc'])
  return (
    <nav id="sidebar">

    <button className="nav-toggle hamburger hamburger--slider mt-1 ml-1" type="button" id="sidebarCollapse">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
      </button>
      <div className="sidebar-content pt-5">
        <div className="mt-5">
          <ul id="sidebar-menu">
            <li className="menu-about">
                <a data-toggle="collapse" href="#collapse-About" role="button" aria-expanded="false" aria-controls="collapse-About">ABOUT</a>
            </li>
            {issuesAndExhibitions.map((item) => (
              <li key={item.sys.id} className="menu-index">
                  <a data-toggle="collapse" href={`#collap-${item.sys.id}`} role="button" aria-expanded="false" aria-controls={`collap-${item.sys.id}`}>{item.fields.title}</a>
              </li>
            ))}
            <li className="pl-2">
              <a href="https://www.facebook.com/Petunia-233486656743243/"><img src={Facebook} className="pr-3" alt="Facebook" /></a>
              <a href="https://www.pinterest.at/petuniamagazine/"><img src={Pinterest} className="pr-3" alt="Pinterest" /></a>
              <a href="mailto:contactpetunia@gmail.com"><img src={Mail} alt="Email" /></a>
            </li>
            </ul>
          </div>
        </div>
    </nav>
  )
}

const mapStateToProps = (state) => {
  const {
    issues,
    exhibitions,
  } = state;
  return {
    issues,
    exhibitions,
  }
}

export default connect(mapStateToProps)(Menu);
