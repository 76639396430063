import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import About from './About';
import IssueCard from './IssueCard';
import Exhibition from './ExhibitionCard';

const IssueOrExhibitionCard = ({i}) => {
  if (i.sys.contentType.sys.id === 'issue') {
    return (<IssueCard issue={i} />)
  } else {
    return (<Exhibition exhibition={i} />)
  }
}

class Contents extends React.Component {
  render() {
    const { issues, exhibitions, about } = this.props
    const issuesAndExhibitions = _.orderBy(issues.concat(exhibitions), ['fields.publishDate'], ['desc'])
    return (
      <div className="accordion" id="petunia-content">
        {issuesAndExhibitions.map((i, index) => (
          <IssueOrExhibitionCard i={i} key={index} />
          ))
        }
        {about ? <About content={about} /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    issues,
    exhibitions,
    about
  } = state;
  return {
    issues,
    exhibitions,
    about
  };
};

export default connect(mapStateToProps)(Contents);
