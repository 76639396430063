import React from 'react';
import RichText from '@madebyconnor/rich-text-to-jsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';
import { withRouter } from "react-router";

class Exhibition extends React.Component {
  state = {
      open: false
  }

  componentDidMount() {
    const { exhibition, match, history } = this.props;
    $(`#collap-${exhibition.sys.id}`).on('shown.bs.collapse', (e) => {
      let target = e.target.id.split("-")[1]
      let position = $(`#${target}`).offset().top;
      $("body, html").animate({
  		    scrollTop: position
      }, 800);
    })

    $(`#collap-${exhibition.sys.id}`).on('show.bs.collapse', () => {
      this.setState({
        open: true
      })
      history.push(`/${exhibition.fields.slug}`)
    })
    $(`#collap-${exhibition.sys.id}`).on('hide.bs.collapse', () => {
      this.setState({
        open: false
      })
      history.push(`/`)
    })
    if (match.params.slug === exhibition.fields.slug) {
      $(`#collap-${exhibition.sys.id}`).collapse('show');
    }
  }

  render() {
    const { exhibition } = this.props;
    return (
      <div
        className="card" id={exhibition.sys.id}>
          <button onClick={this.handleOnClick} className="btn btn-link btn-block text-left collapsed black btn-exhibition" type="button" data-toggle="collapse" data-target={`#collap-${exhibition.sys.id}`} aria-expanded="true" aria-controls={`collap-${exhibition.sys.id}`}>
            {exhibition.fields.title} {this.state.open ? '—' : '+'}
          </button>

        <div id={`collap-${exhibition.sys.id}`} className="collapse" aria-labelledby={`collap-${exhibition.sys.id}`} data-parent="#petunia-content">
          <div className="card-body pr-0">
            <div className="exhibition-images">
              {exhibition.fields.images.map((images) => (
                <LazyLoadImage key={images.sys.id} src={images.fields.file.url} alt={images.fields.title} className={'pr-3'} />
              ))}
            </div>
            <div className="exhibition-text pt-3">
              <RichText richText={exhibition.fields.text} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default withRouter(Exhibition);
