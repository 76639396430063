import React from 'react';
import Cover1 from '../img/petunia-cover-1.jpg';
import Cover2 from '../img/petunia-cover-2.jpg';
import Cover3 from '../img/petunia-cover-3.jpg';
import Cover4 from '../img/petunia-cover-4.jpg';
import Cover5 from '../img/petunia-cover-5.jpg';
import Cover6 from '../img/petunia-cover-6.jpg';
import Cover7 from '../img/petunia-cover-7.jpg';

const images = [Cover1, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7, Cover1, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7, Cover1, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7, Cover1, Cover2, Cover3, Cover4, Cover5, Cover6, Cover7]

const Header = () => (
  <div className="row header">
    <div className="col-12 fadeIn">
    {images.map((img, index) => (
      <img
        key={`cover-${index}`}
        alt="cover"
        src={img}
        className="animated fadeIn"
        style={
          {
            animationDuration: '0.1s',
            animationDelay:`${index*0.5+0.1}s`,
            top: `${Math.floor(Math.random() * 40 - 5)}%`,
            left: `${Math.floor(Math.random() * 80 - 0)}%`}
        }
      />
    ))}
    </div>
  </div>
)

export default Header;
