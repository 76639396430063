import React from 'react';
import RichText from '@madebyconnor/rich-text-to-jsx';
import { withRouter } from "react-router";
import 'animate.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';


class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizeBig: false
    };
  }
  onClick() {
    this.setState({
      sizeBig: !this.state.sizeBig
    })
  }
  render() {
    return (
      <LazyLoadImage
        onClick={() => this.onClick()}
        src={this.props.src}
        alt={this.props.alt}
        className={`${this.state.sizeBig ? "bigImage" : ""}`}
        />
    )
  }
}

class IssueCard extends React.Component {
  state = {
      open: false
  }

  componentDidMount() {
    const { issue, match, history } = this.props;
    $(`#collap-${issue.sys.id}`).on('shown.bs.collapse', (e) => {
      let target = e.target.id.split("-")[1]
      let position = $(`#${target}`).offset().top;
      $("body, html").animate({
  		    scrollTop: position
      }, 800);
    })

    $(`#collap-${issue.sys.id}`).on('show.bs.collapse', () => {
      this.setState({
        open: true
      })
      history.push(`/${issue.fields.slug}`)
    })
    $(`#collap-${issue.sys.id}`).on('hide.bs.collapse', () => {
      this.setState({
        open: false
      })
      history.push(`/`)
    })
    if (match.params.slug === issue.fields.slug) {
      $(`#collap-${issue.sys.id}`).collapse('show');
    }
  }

  render() {
    const { issue } = this.props;
    return (
      <div
        className="card"
        style={{
          backgroundImage: `url(${issue.fields.backgroundImage.fields.file.url})`,
          backgroundSize: 'cover'
        }}
        id={issue.sys.id}>
          <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={`#collap-${issue.sys.id}`} aria-expanded="true" aria-controls={`collap-${issue.sys.id}`}>
            {issue.fields.title} {this.state.open ? '—' : '+'}
          </button>

        <div id={`collap-${issue.sys.id}`} className="collapse" aria-labelledby={`collap-${issue.sys.id}`} data-parent="#petunia-content">
          <div className="card-body p-0">
            <div className="issue-images ml-0 ml-md-4 mt-3 mb-md-5 mb-2 animated slideInRight delay-03">
              {issue.fields.pages.map((page) => (
                <Page key={page.sys.id} src={page.fields.file.url} alt={page.fields.title} />
              ))}
            </div>
            <div className="issue-text mt-3 p-3">
              <RichText richText={issue.fields.credits} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(IssueCard);
