import { LOAD_ISSUES, LOAD_EXHIBITIONS, LOAD_ABOUT } from './actions';

export const Issues = (state = [], action) => {
  if (action.type === LOAD_ISSUES) {
    return action.issues
  }
  return state
}

export const Exhibitions = (state = [], action) => {
  if (action.type === LOAD_EXHIBITIONS) {
    return action.exhibitions
  }
  return state
}

export const About = (state = null, action) => {
  if (action.type === LOAD_ABOUT) {
    return action.about
  }
  return state
}
