export const LOAD_ISSUES = "LOAD_ISSUES"
export const LOAD_EXHIBITIONS = "LOAD_EXHIBITIONS"
export const LOAD_ABOUT = "LOAD_ABOUT"
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE"

export const LoadIssues = issues => ({
  type: LOAD_ISSUES,
  issues
})

export const LoadExhibitions = exhibitions => ({
  type: LOAD_EXHIBITIONS,
  exhibitions
})

export const LoadAbout = about => ({
  type: LOAD_ABOUT,
  about
})
