import React from 'react';

const Modal = () => (
  <div className="modal fade" id="modal-impressum" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">DATA PROTECTION POLICY</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>This data protection policy informs you about the use of your personal data on the PETUNIAMAGAZINE.EU website.</p>

          <p>You can visit our website without providing any personal information. Whenever a web page is called up, the web server merely automatically stores access data in so-called server log files which are automatically communicated by your browser. This includes data such as the name of the requested file, the last visited website, the date and time of the retrieval, the browser used, the amount of data transmitted, your IP address, the requesting provider etc. Within the scope of a processing procedure on our behalf, a third-party provider provides the services for hosting and displaying the website. This service provider is located within a country in the European Union or the European Economic Area.</p>

          <p>NEWSLETTER<br />
          Our newsletter is sent by the email service provider MailChimp. After you have registered for our newsletter, your e-mail address will be sent to MailChimp. MailChimp is prohibited from selling or using your e-mail address for any purpose other than sending newsletters.

          The newsletter is sent on the basis of your consent. You can unsubscribe from our newsletter at any time, i.e. withdraw your consent. A link to unsubscribe from the newsletter can be found at the end of every newsletter. In accordance with legal requirements, your e-mail address will be deleted when you unsubscribe from the newsletter. If you subscribe to one of our newsletters, we will use the information required or separately provided by you to periodically send you the subscribed email newsletter. The sending of email newsletters takes place on the basis of your separate explicit consent according to Art. 6 (1) lit. a GDPR. For security reasons, we use the so-called double opt-in procedure: We will only send you a newsletter by email if you have previously confirmed your newsletter subscription. For this purpose, we will send you an email confirming the subscription via the link contained therein. We want to make sure that only you as the owner of the specified email address can subscribe to the newsletter. The newsletter is sent as part of a processing order on our behalf by a service provider, to which we pass on your email address. You may object to this use of your email address at any time by sending a message to the contact details provided below or through a dedicated link in the email message, without incurring any costs other than the transmission costs according to the basic tariffs.
          </p>

          <p>SOCIAL NETWORKS<br />
          Our website uses social buttons from social networks.
          In order to increase the protection of your data when visiting our website, these buttons are not unrestricted, but merely integrated into the site using an HTML link. This integration ensures that when you visit a page of our website that contains such buttons, no connection with the servers of the provider of the respective social network is established.
          Clicking on one of the buttons opens a new window in your browser and calls up the page of the respective service provider on which you can (after entering your login data, if required), for example, hit the Like or Share button.For more information on the purpose and scope of the data collection and the further processing and use of the data by the providers on their pages, as well as for contact details and your related rights and settings options to protect your privacy, please refer to the provider’s privacy policy.</p>

          <p>COOKIES<br />
          The website uses cookies in some instances. Cookies do not harm your computer and do not contain viruses. Cookies serve to make our site more user-friendly, effective, and secure. Cookies are small text files that are stored on your computer and saved by your browser.
          Most of the cookies we use are so-called “session cookies.” They are automatically deleted after your browsing session. Other cookies remain stored on your device until you delete them. These cookies allow us to recognize your browser the next time you visit. You can set your browser so that you are informed about the placement of cookies and can only enable cookies in one-off instances, refuse to accept cookies in certain cases or in general, as well as activating the automatic deletion of cookies when closing the browser. Disabling cookies may limit the functionality of this website.
          </p>

          <p>GOOGLE ANALYTICS<br />
          For some of our applications, data are collected and stored for marketing and optimization purposes, using the technologies of Google Analytics (https://www.google.com). The privacy policy of Google can be accessed here: https://policies.google.com/privacy

          Anonymous user profiles may be created from site interaction data (site access, duration of visit, place of access, information about your terminal).
          </p>
          <p>LINKS TO OTHER WEBSITES<br />
          Our site contains links to external third party websites whose contents we cannot control. Therefore, we cannot assume any liability for this external content. The respective provider or operator of the site is always responsible for the contents of the link. The links were checked for possible legal violations at the time the link was added and no illegal content was not identified at this time. However, it is not reasonable to have permanent content control of links without concrete evidence of a legal violation. We will remove such links immediately upon notification of any infringement.
          </p>

          <p>YOUR RIGHTS AS A DATA SUBJECT<br />
          Where your personal data is collected during your visit to our website, you have the following rights as the “data subject” under the GDPR:
          <br />
          Right to revoke consent (Article 7 GDPR)<br />
          Right of access by the data subject (Article 15 GDPR)<br />
          Right to rectification (Article 16 of the GDPR)<br />
          Right to erasure “Right to be forgotten” (Article 17 GDPR)<br />
          Right to restriction of processing (Article 18 GDPR)<br />
          Notification obligation regarding rectification or erasure of personal data or restriction of processing (Article 19 GDPR)<br />
          Right to data portability (Article 20 GDPR)<br />
          Right to object (Article 21 GDPR)<br />
          Right to lodge a complaint with a supervisory authority (Article 77 GDPR)<br />
          If you would like to exercise your rights, please send an e-mail to contactpetunia@gmail.com.
          </p>

          <p>DATA PROTECTION<br />
          We protect our website and other systems through technical and organisational measures against the loss, destruction, access, modification or dissemination of your data by unauthorised persons.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Modal;
