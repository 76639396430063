import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

const AppRouter = () => (
  <Provider store={store}>
     <Router>
       <Switch>
         <Route exact path="/" component={App} />
         <Route path="/:slug" component={App} />
       </Switch>
     </Router>
   </Provider>
)

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
