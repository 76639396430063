import React, { Component } from 'react';

import $ from "jquery";
import 'popper.js';
import 'bootstrap';
import { connect } from 'react-redux';
import { getContent } from './content';
import { LoadIssues, LoadExhibitions, LoadAbout } from './actions';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'hamburgers/dist/hamburgers.min.css';
import './style.css';
import './responsive.css';

import Menu from './components/Menu';
import Footer from './components/Footer';
import Header from './components/Header';
import NewsList from './components/News';
import Contents from './components/Contents';
import Modal from './components/Modal'


class App extends Component {
  componentWillMount() {
    this.props.LoadAbout();
    this.props.LoadIssues();
    this.props.LoadExhibitions();
  }
  componentDidMount(){
    $('#sidebarCollapse').on('click', () => {
        $('#sidebar').toggleClass('active');
        $('#sidebar-menu').toggle();
        $('#content').toggleClass('active');
        $(".hamburger").toggleClass("is-active");
    });
  }
  render() {
    return (
        <div className="wrapper">
          <Menu />
          <div id="content">
            <div className="container-fluid">
              <Header />
              <NewsList />
              <Contents />
              <Footer />
              <Modal />
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    issues
  } = state;
  return {
    issues
  };
};

const mapDispatchToProps = dispatch => ({
  LoadIssues: () => {
    getContent("issue").then((response) => {
      dispatch(LoadIssues(response.items))
    })
  },
  LoadExhibitions: () => {
    getContent("exhibition").then((response) => {
      dispatch(LoadExhibitions(response.items))
    })
  },
  LoadAbout: () => {
    getContent("about").then((response) => {
      dispatch(LoadAbout(response.items[0]))
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
