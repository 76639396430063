import {createClient} from 'contentful';
import {accessToken, spaceId} from './settings';

const contentfulClient = createClient({
  space: spaceId,
  accessToken: accessToken,
  include: 2,
})

export const getContent = (contentType, limit, skip, order) => {
  return contentfulClient.getEntries({
    content_type: contentType,
    order: order,
    limit: limit,
    skip: skip
  })
}
