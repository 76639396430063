import React from 'react';
import RichText from '@madebyconnor/rich-text-to-jsx';
import $ from 'jquery';
import { withRouter } from "react-router";

class About extends React.Component {
  state = {
    open: false
  }
  componentDidMount() {
    const { content, match, history } = this.props;
    $('#collapse-About').on('shown.bs.collapse', (e) => {
      let position = $('#About').offset().top;
      $("body, html").animate({
  		    scrollTop: position
      }, 800);
    })

    $('#collapse-About').on('show.bs.collapse', () => {
      this.setState({
        open: true
      })
      history.push(`/${content.fields.slug}`)
    })
    $('#collapse-About').on('hide.bs.collapse', () => {
      this.setState({
        open: false
      })
      history.push(`/`)
    })
    if (match.params.slug === content.fields.slug) {
      $(`#collapse-About`).collapse('show');
    }
  }

  render() {
    const { content } = this.props;
    return (
      <div className="card" id="About">
        <button className="btn btn-link btn-block text-left collapsed black" type="button" data-toggle="collapse" data-target="#collapse-About" aria-expanded="true" aria-controls="collapse-About">
          {content.fields.title} {this.state.open ? '—' : '+'}
        </button>
        <div id="collapse-About" className="collapse" aria-labelledby="collapse-About" data-parent="#petunia-content">
          <div className="card-body">
            <RichText richText={content.fields.text} />
          </div>
        </div>
      </div>
    )
  }
}



export default withRouter(About);
