import React from 'react';
import { getContent } from '../content';
import RichText from '@madebyconnor/rich-text-to-jsx';
import Moment from 'react-moment';

class NewsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      news: []
    }
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  componentWillMount() {
    getContent("news", 2, 0, '-fields.date').then((response) => {
      this.setState({
        news: response.items,
        skip: response.skip,
        total: response.total
      })
    })
  }
  handleOnClick() {
    if (this.state.skip + 2 === this.state.total) {
      var skip = 0
    } else {
      // eslint-disable-next-line
      var skip = this.state.skip + 2
    }
    getContent("news", 2, skip, '-fields.date').then((response) => {
      this.setState({
        news: response.items,
        skip: response.skip,
        total: response.total
      })
    })
  }
  render() {
    return (
      <div className="row news align-self-start pl-2">
        <div className="news-title col-md-4 col-12 pl-0 pr-2 pt-0">
          NEWS
          <span className="news-arrow-mobile ml-3">→</span>
        </div>


        {this.state.news.map((news) => (
          <div className="col-md-4 col-12 p-3 pr-5 pl-5 news-content animated slideInRight" key={news.sys.id}>
            <span className="news-date pl-1 pr-1"> <Moment format="DD.MM.YYYY">{news.fields.date}</Moment></span>
            <RichText className="news-text mb-0" richText={news.fields.content} />
            </div>
          ))}
        <div onClick={this.handleOnClick} className="news-arrow pr-3">→</div>
    </div>)
  }
}

export default NewsList;
