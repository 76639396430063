import React from 'react';
import Facebook from '../img/facebook.svg';
import Pinterest from '../img/pinterest.svg';
import Mail from '../img/mail.png';
import Goethe from '../img/goethe-logos.png';

const Footer = () => {
  return (
    <div className="row footer pt-5">
    <div className="col-12 col-md-6 pt-md-5 pl-4 pb-md-3 footer-left">
      <p>
      <a href="https://www.facebook.com/Petunia-233486656743243/"><img src={Facebook} className="pr-3" alt="Facebook" /></a>
      <a href="https://www.pinterest.at/petuniamagazine/"><img src={Pinterest} className="pr-3" alt="Pinterest" /></a>
      <a href="mailto:contactpetunia@gmail.com"><img src={Mail} alt="Email" /></a>
      </p>
      <p>
        <a href="#modal-impressum" data-toggle="modal" data-target="#modal-impressum">Data Privacy/Impressum</a>
      </p>
      <p>Website by <a href="http://www.ocrestudio.co">ocré studio</a></p>
      <p>
        © Pétunia, all rights reserved, 2009-2019
      </p>
      </div>
      <div className="col-12 col-md-6 footer-link footer-goethe text-right my-auto pr-4 pb-2">
        <p><img src={Goethe} alt="Email" /></p>
      </div>

    </div>
  )
}

export default Footer;
